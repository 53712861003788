import * as noUiSlider from 'nouislider';
import { inputIntValue, localizedFormatMoney } from '../tools';
import { isSupportRestoreMode, push_GA_checkout_event } from './tools';

export function initPledgeButtons($form) {
  const $pledge_input = $form.find('#id_pledge');
  const $input_box = $form.find('.input-box:first');

  $('#pledge-presets').on('click', '.js-pledge-preset', function() {
    var $this = $(this),
      active_class = 'selected';
    $pledge_input.triggerHandler('focus.remove-errors');

    if ($this.find('.btn').hasClass(active_class)) {
      return;
    }

    $this
      .siblings()
      .find('.btn')
      .removeClass(active_class);
    $this.find('.btn').addClass(active_class);

    push_GA_checkout_event($form, 1, $this.attr('value') || 'custom', 'Select amount');

    if ($this.is('.js-custom')) {
      $input_box.removeClass('d-none');
      if (!isSupportRestoreMode($form)) {
        $pledge_input.val('');
      }
      $pledge_input.focus();
    } else {
      $input_box.addClass('d-none');
      $pledge_input.val(parseInt($this.attr('value'))).trigger('change');
    }
  });
}

export function initPledgeButtonsExp46B($form) {
  const $pledge_input = $form.find('#id_pledge');
  const $pledge_helper_input = $form.find('#id_pledge_helper');

  $('#pledge-presets').on('click', '.js-pledge-preset', function() {
    var $this = $(this),
      active_class = 'selected';
    $pledge_input.triggerHandler('focus.remove-errors');

    if ($this.find('.btn').hasClass(active_class)) {
      return;
    }

    $this
      .siblings()
      .find('.btn')
      .removeClass(active_class);
    $this.find('.btn').addClass(active_class);

    push_GA_checkout_event($form, 1, $this.attr('value') || 'custom', 'Select amount');

    $pledge_helper_input.val(null); // clear custom pledge value
    $pledge_input.val(parseInt($this.attr('value')));
    $pledge_input.trigger('change');
  });
}

export function createSlider(
  donation_slider_default_value,
  slider_step,
  update_summary_function,
  $form,
  extra_function,
) {
  let slider;

  // We don't need a lengthy description here, all of the action takes place in the first page section.
  const $custom_donation_helper = $form.find('#id_donation_helper');
  $custom_donation_helper.addClass('default-size');

  $form.find('.js-toggle-donation-panel').on('click', function(e) {
    e.preventDefault();

    $form.find('.js-donation-panel').toggleClass('hidden');
    // handles case when pledge is changed while slider is hidden
    slider.noUiSlider.set(slider.noUiSlider.get());
    update_summary_function($form);

    // Handle edge cases of changing slider/custom donation values, depending on which one is being show atm.
    extra_function(
      $custom_donation_helper.is(':visible')
        ? $custom_donation_helper.val() === '0'
        : slider.noUiSlider.get() === 0,
    );
    // return false;
  });

  const slider_min_val = 0;
  const slider_max_val = 30;
  let slider_start = donation_slider_default_value;

  const $custom_donation = $form.find('#id_donation');
  const $pledge = $form.find('#id_pledge');

  // Unused now, but let's keep support restore feature for the future use
  if (isSupportRestoreMode($form)) {
    const donation_percentage = ($custom_donation.val() * 100) / $pledge.val();
    if (
      donation_percentage >= slider_min_val &&
      donation_percentage <= slider_max_val &&
      donation_percentage % slider_step === 0
    ) {
      slider_start = donation_percentage;
    } else {
      $form.find('.js-donation-panel').toggleClass('hidden');
      $custom_donation_helper.val($custom_donation.val());
    }
  }

  slider = document.getElementById('donation-slider');
  noUiSlider.create(slider, {
    start: slider_start,
    range: {
      min: slider_min_val,
      max: slider_max_val,
    },
    step: slider_step,

    format: {
      to: function(value) {
        return Math.floor(value);
      },
      from: function(value) {
        return Number(value);
      },
    },
    tooltips: {
      // 'to' the formatted value. Receives a number.
      to: function(value) {
        value = Math.floor(value);
        const donation_perc_label = value + '%';
        const pledge_val = inputIntValue($pledge);
        if (pledge_val > 0) {
          const localized_donation_value = localizedFormatMoney(
            (pledge_val * value) / 100,
            2,
          );
          return (
            localized_donation_value +
            ' ' +
            gettext('cmn-currency-symbol') +
            ' (' +
            donation_perc_label +
            ')'
          );
        } else {
          return donation_perc_label;
        }
      },
      // 'from' the formatted value.
      // Receives a string, should return a number.
      from: function(value) {
        if (value.endsWith(')')) {
          value = value.split(' ')[2];
        }
        return Number(value.replace(/[()%]/g, ''));
      },
    },
    connect: 'lower',
    behaviour: 'tap-drag',
  });

  slider.noUiSlider.on('update', (values) => {
    update_summary_function($form);

    //Triggers when the slider is moved
    extra_function(values[0] === 0);
  });

  return slider;
}
