import { inputIntValue, isMobileWidth } from '../tools';
import { push_GA_checkout_event, storeGAid } from './tools';
import { createGooglePayPaymentRequest } from './providers/stripe';

export function intIncreasePledgeToMinValue($form) {
  // For update pledge popups when pledge is lower then min for given channel
  $(document).on('click', '.js-change-pledge', function() {
    const new_pledge = parseInt($(this).data('value'));
    $form
      .find('#id_pledge')
      .val(new_pledge)
      .trigger('change');

    // select matching pledge preset if present
    const $pledge_preset = $form.find(
      `.js-pledge-preset[value="${new_pledge}"]`,
    );
    if ($pledge_preset.length) {
      $pledge_preset.click();
    }
    pomagamy.close_popup();
  });
}

export function initTosCheckEvent($eventForm) {
  $('#id_accept_pomagam_tos').on('click', function() {
    push_GA_checkout_event($eventForm, 3, undefined, 'Accept Terms');
  });
}

export function initPaymentChannelsEvents($form) {
  const mobile = isMobileWidth();
  $form.find('.bank-option > label').on('click', function(e) {
    // Due to some internal shenanigans,
    // `.bank-option` click event triggers twice
    // with different targets (input and image).
    // This is a workaround to prevent double triggering of the event.
    // Additionally, remember to DO NOT directly bind on .bank-option element
    // because it's bigger than its visual representation.
    if (e.target.nodeName === 'INPUT') {
      return;
    }

    var $this = $(this);
    $this.find('input').triggerHandler('focus.remove-errors');

    push_GA_checkout_event(
      $form,
      2,
      $this.find('input').attr('value'),
      'Select payment method',
    );

    setTimeout(function() {
      var $data_box = $form.find('.user-data-box'),
        $target,
        offset;

      if (parseInt($form.find('#id_pledge').val() || 0) === 0) {
        $target = $form;
        offset = 0;
      } else if ($data_box.parent().is('.payment-box')) {
        const $summary_waypoint = $form.find('#js-summary-waypoint');
        if ($summary_waypoint.is(':visible')) {
          $target = $summary_waypoint;
          offset = mobile ? -35 : 0;
        } else {
          $target = $form.find('.section-summary');
          offset = mobile ? 30 : 0;
        }
      } else {
        // data box was moved into payment channel section
        $target = $data_box;
        offset = -20;
      }
      pomagamy.scrollToElem($target, offset);
    }, 350);
  });
}

export function initShowAllBanksEvent($form) {
  let $btn = $form.find('#show-all-banks');
  $btn.on('click', function() {
    $form.find('.bank-list.tease-gradient').removeClass('tease-gradient');
    $form.find('.bank-option').removeClass('hidden');
    $btn.remove();
  });
}

export function initOnlineSubmitEvent($form) {
  const PAYPAL = 'PayPal';
  const STRIPE = 'Stripe';
  const GOOGLE_PAY = 'Google Pay';
  const APPLE_PAY = 'Apple Pay';
  const CREDIT_CARD = gettext('supwiz-channel-credit-card');
  const $ga_client_id = $form.find('#id_ga_client_id');
  const $pledge = $form.find('#id_pledge');
  const $donation = $form.find('#id_donation');
  const allow_stripe = $form.hasClass('js-allow-stripe');
  const allow_paypal = $form.hasClass('js-allow-paypal');
  const allow_zen_cc = $form.hasClass('js-allow-zen-cc');
  const allow_zen_apple_pay = $form.hasClass('js-allow-zen-apple-pay');

  $form.on('click', '#submit-btn', function() {
    storeGAid($ga_client_id);

    const channel_name = $form
      .find('input[name=payment_channel]:checked')
      .next('img')
      .attr('alt');

    const provided_by_PayPal =
      channel_name === PAYPAL ||
      (channel_name === CREDIT_CARD && !allow_stripe && !allow_zen_cc);
    // Credit Card channel is provided by PayPal if there's no Stripe nor Zen (from backend)
    const provided_by_Stripe =
      channel_name === STRIPE ||
      channel_name === GOOGLE_PAY ||
      (channel_name === CREDIT_CARD && allow_stripe && !allow_zen_cc);
    // Credit Card channel is provided by Stripe if it's available and Zen is disabled (from backend)
    const provided_by_Zen_cc = (channel_name === CREDIT_CARD && allow_zen_cc);
    const provided_by_Zen_ApplePay = (channel_name === APPLE_PAY && allow_zen_apple_pay);

    const pledge = inputIntValue($pledge);
    const donation = inputIntValue($donation);
    // This value is used only for the UI elements (GooglePay flow).
    // The real (billing) tx value is passed to Stripe by the backend api call,
    // creating a PaymentIntent.
    const total_cost = pledge + donation;

    function pledgeTooLow(popup_selector) {
      const $elem = $(`${popup_selector} .js-change-pledge`);
      return pledge < parseInt($elem.data('value'));
    }

    if (provided_by_PayPal && pledgeTooLow('#paypal-popup')) {
      pomagamy.show_popup('#paypal-popup');
    } else if (provided_by_Stripe && pledgeTooLow('#stripe-popup')) {
      // includes Google Pay
      pomagamy.show_popup('#stripe-popup');
    } else if (provided_by_Zen_cc && pledgeTooLow('#zen-popup-cc')) {
      pomagamy.show_popup('#zen-popup-cc');
      if ($.fn.stopPulseLoading) {
        $.fn.stopPulseLoading();
      }
    } else if (provided_by_Zen_ApplePay && pledgeTooLow('#zen-popup-apple-pay')) {
      pomagamy.show_popup('#zen-popup-apple-pay');
      if ($.fn.stopPulseLoading) {
        $.fn.stopPulseLoading();
      }
    } else if (channel_name === GOOGLE_PAY) {
      createGooglePayPaymentRequest(total_cost);
      $form.submit();
    } else {
      $form.submit();
    }
  });
}

export function initPledgeEvents($pledge, slider, summary_update_function) {
  $pledge.on('keyup change', function() {
    // fake slider update to rerender its label
    if ($(slider).is(':visible')) {
      slider.noUiSlider.set(slider.noUiSlider.get());
    }
    summary_update_function();
  });

  $pledge.siblings('i.currency').on('click', function() {
    $pledge.focus();
  });
}

export function initPledgeEventsExp46B($form, $pledge, slider, summary_update_function) {
  const $pledge_helper = $form.find('#id_pledge_helper');

  $pledge_helper.on('keyup change', function(e) {
    // update pledge value when helper input changes
    $pledge.val(e.target.value);
    $pledge.trigger('change');
  });

  $pledge.on('change', function() {
    // fake slider update to rerender its label
    if ($(slider).is(':visible')) {
      slider.noUiSlider.set(slider.noUiSlider.get());
    }
    summary_update_function();
  });

  $pledge_helper.siblings('i.currency').on('click', function() {
    $pledge_helper.focus();
  });

  $pledge_helper.on('keydown', function() {
    // remove selection from pledge preset if user types anything in pledge input field
    $form.find('div.btn.btn-outline.selected').removeClass('selected');
  });
}

export function initCustomDonationHelperEvents(
  $form,
  summary_update_function,
  on_blur_function,
) {
  $form
    .find('#id_donation_helper')
    .on('keyup', function() {
      summary_update_function();
    })
    .on('blur', function() {
      // Triggers when focus is lost on the custom donation input
      on_blur_function($(this).val() === '0');
    });
}
